.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.detailsTabs {
  margin-top: 0px !important;
}

.p-splitter-gutter {
  height: 10px !important;
}

/* ::-webkit-datetime-edit {
  color: #818584;
} */

.required {
  color: crimson;
}

.card {
  height: 60px;
  /* TODO: How to get colour from variable? */
  border: 1px solid #c0c2c1;
  border-radius: 50px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

th.rotate {
  white-space: nowrap;
  padding: 0;
}

th.rotate > div {
  transform: translate(0px, 14px) rotate(-45deg);
  width: 15px;
}
th.rotate > div > span {
  padding: 5px 4px;
}

.required-label {
  color: crimson;
  font-size: 10px;
  text-align: left;
  padding-bottom: 10px;
}
